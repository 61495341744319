import React from "react";
import moment from "moment";
import Helmet from "../components/Helmet";

import CallToAction from "../components/CallToAction";

function Contact() {
    let hour = moment().hour();
    let opened = hour >= 8 && hour < 17;
    let weekDay = moment().isoWeekday();
    if (weekDay === 6 || weekDay === 7) opened = false;

    return (
        <React.Fragment>
            <Helmet title="Contact"/>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Contact</h1>
                </div>
            </div>
            <div className="container mb-5">
                { opened ? (
                    <React.Fragment>
                        <h2 className="mb-0">
                            Bel <a href="tel:+31174235120">0174 235 120</a>
                        </h2>
                        <h3 className="text-muted mt-0">
                            <small>Bereikbaar tot 17:00</small>
                        </h3>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <h2 className="mb-0">
                            Mail naar <a href="mailto:info@smithsolar.nl">info@smithsolar.nl</a>
                        </h2>
                        <h3 className="text-muted mt-0">
                            <small>Geopend vanaf 8:00 { (weekDay === 6 || weekDay === 7) && "op maandag" }</small>
                        </h3>
                    </React.Fragment>
                )}
                <p style={{ fontSize: "20px" }} className="mt-4">
                    Smith Solar B.V.<br/>
                    Nobelstraat 11 BU4<br/>
                    2693 BC 's-Gravenzande<br/>
                    The Netherlands<br/>
                    <br/>
                    Telefoon: <a href="tel:+31174235120">0174 235 120</a><br/>
                    Email: <a href="mailto:info@smithsolar.nl">info@smithsolar.nl</a><br/>
                    KvK: 60218452
                </p>
            </div>
            <iframe id="map" title="map" width="100%" height="400" frameBorder="0" style={ { border: 0 } }
                    src="https://maps.google.com/maps?q=Smith+Solar+B.V.&z=14&output=embed" allowFullScreen/>
            <CallToAction/>
        </React.Fragment>
    )
}

export default Contact;
