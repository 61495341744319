import React from "react";

import NavbarLink from "../NavbarLink";
import SubNavbar from "../SubNavbar";

// <DropdownLink to="/producten/" title="Producten" onClick={ collapse } exact/>
// <DropdownLink to="/zonnepanelen/" title="Over zonnepanelen" onClick={ collapse } exact/>
// <DropdownLink to="/zonnepanelen/voordelen/" title="Voordelen van zonnepanelen" onClick={ collapse } exact/>
// <DropdownLink to="/zonnepanelen/mono-of-poly/" title="Mono of Poly zonnepanelen" onClick={ collapse } exact/>
// <DropdownLink to="/zonnepanelen/installeren/" title="Installeren van zonnepanelen" onClick={ collapse } exact/>
// <DropdownLink to="/btw/" title="0% btw" onClick={ collapse } exact/>
// <DropdownLink to="/subsidie/" title="Zakelijke SDE Subsidie" onClick={ collapse } exact/>

function ZonnepanelenSubNavbar() {
    return (
        <SubNavbar>
            <NavbarLink to="/zonnepanelen/" exact>
                <i className="fa-solid fa-house fa-fw mr-2"/>
                Overzicht
            </NavbarLink>
            <NavbarLink to="/producten/" exact>
                <i className="fa-solid fa-solar-panel fa-fw mr-2"/>
                Producten
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/voordelen/" exact>
                <i className="fa-solid fa-thumbs-up fa-fw mr-2"/>
                Voordelen
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/installeren/" exact>
                <i className="fa-solid fa-tools fa-fw mr-2"/>
                Installatie
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/btw/" exact>
                <i className="fa-solid fa-circle-euro fa-fw mr-2"/>
                0% btw
            </NavbarLink>
            <NavbarLink to="/zonnepanelen/subsidie/" exact>
                <i className="fa-solid fa-buildings fa-fw mr-2"/>
                Zakelijke SDE Subsidie
            </NavbarLink>
        </SubNavbar>
    );
}

export default React.memo(ZonnepanelenSubNavbar);
