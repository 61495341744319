import React, {
    createContext,
    useEffect,
    useState
} from "react";
import {
    useRouteMatch,
    withRouter
} from "react-router-dom";
import moment from "moment";

const ThemeContext = createContext(null);

function ThemeManagerInternal({ location, children }) {
    const [currentNavbarHeight, setCurrentNavbarHeight] = useState(null);
    const date = moment().format("YYYY-MM-DD");
    const year = moment().format("YYYY");
    const blackFriday = (moment(date) >= moment("2023-11-12") && moment(date) <= moment("2023-11-25")) || location.search === "?blackfriday";
    const christmas = (moment(date) > moment(year + "-12-05") && moment(date) < moment(year + "-12-27")) || location.search === "?christmas";

    const homeMatch = useRouteMatch({
        path: [
            "/",
            "/campagne/boelhuis",
            "/campagne/gazetta",
            "/campagne/helmheus",
            "/campagne/mobile",
            "/campagne/mobile-gasprijzen",
            "/campagne/mobile-actie"
        ],
        exact: true
    });
    const darkNavbar = blackFriday && homeMatch !== null;
    const hideLinks = useRouteMatch({
        path: [
            "/btwterugvraag/terugvraag",
            "/btwterugvraag/terugvraag/:token"
        ],
        exact: true
    }) !== null;

    const [context, setContext] = useState({
        darkNavbar,
        blackFriday,
        christmas,
        hideLinks
    });

    useEffect(() => {
        setContext(prevContext => {
            return { ...prevContext, darkNavbar, hideLinks }
        });
    }, [location, darkNavbar, hideLinks]);

    return (
        <ThemeContext.Provider value={{
            ...context,
            currentNavbarHeight,
            setCurrentNavbarHeight
        }}>
            { children }
        </ThemeContext.Provider>
    )
}
export const ThemeManager = withRouter(ThemeManagerInternal);

export function withThemeContext(Component) {
    return function contextComponent(props) {
        return (
            <ThemeContext.Consumer>
                { context => <Component {...props} themeContext={ context } /> }
            </ThemeContext.Consumer>
        )
    }
}

export default ThemeContext;
