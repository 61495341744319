import React, {useContext} from 'react';
import moment from "moment";
import {
    Carousel
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";
import ThemeContext from "../context/ThemeContext";

import whiteLogo from "./../img/solar-logo-white.svg";
import slideSunset from "../img/home/sunset.jpg";
import banner from "./../img/solar-banner.jpg";
import slideParticulieren from "./../img/home/particulieren.jpg";
import slideBedrijven from "./../img/home/bedrijven.jpg";
import slideVerenigingen from "./../img/home/verenigingen.jpg";
import slideBlackFriday from "./../img/home/full-black-friday.jpg";
import Helmet from "../components/Helmet";
import HomeCard from "../components/HomeCard";

function Home() {
    const theme = useContext(ThemeContext);

    let hour = moment().hour();
    let opened = hour >= 8 && hour < 17;
    let weekDay = moment().isoWeekday();
    if (weekDay === 6 || weekDay === 7) opened = false;

    return (
        <React.Fragment>
            <Helmet/>

            <Carousel className="carousel carousel-home slide">
                { theme.blackFriday && (
                    <Carousel.Item style={{ backgroundImage: "url('" + slideBlackFriday + "')" }}>
                        <Carousel.Caption>
                            <h1>5% korting op alle installaties</h1>
                            <Link to="/offerte/" className="btn btn-primary"
                               style={{ backgroundColor: "white", color: "black", borderColor: "white" }}>
                                Offerte aanvragen
                            </Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                )}
                <Carousel.Item style={{ backgroundImage: "url('" + slideSunset + "')" }}>
                    <Carousel.Caption>
                        <img src={ whiteLogo } style={{ width: "50%", maxWidth: "800px" }} alt="Smith Solar B.V."/>
                        <h1 className="slogan">Specialist in zonne-energie</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + banner + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen van Smith Solar B.V.</h1>
                        <Link to="/offerte/" className="btn btn-primary">
                            Offerte aanvragen
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + slideParticulieren + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen voor particulieren</h1>
                        <a href="https://referenties.smithsolar.nl/search/type:particulieren"
                           className="btn btn-primary">Onze particuliere referenties</a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + slideBedrijven + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen voor bedrijven</h1>
                        <a href="https://referenties.smithsolar.nl/search/type:bedrijven"
                           className="btn btn-primary">Onze bedrijven referenties</a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + slideVerenigingen + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen voor verenigingen</h1>
                        <a href="https://referenties.smithsolar.nl/search/type:verenigingen"
                           className="btn btn-primary">Onze verenigingen referenties</a>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <div className="container" style={{ marginTop: "30px" }}>

                <div className="text-center">
                    <h4>
                        Smith Solar B.V. is een bedrijf dat zich specialiseert in groene energie zoals zonnepanelen, energieopslag en laadpalen.
                    </h4>
                </div>

                <br/>

                <div className="row">
                    <div className="col-md-6">
                        <HomeCard
                            icon="fas fa-phone"
                            title={
                                opened ? (
                                    <React.Fragment>
                                        Bel <a href="tel:+31174235120">0174 235 120</a>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        Mail naar <a href="mailto:info@smithsolar.nl">info@smithsolar.nl</a>
                                    </React.Fragment>
                                )
                            }
                            description="Neem direct contact op om zo snel mogelijk een persoonlijke oplossing voor uw dak te zoeken."
                            link="/contact/"
                            linkText="Direct contact opnemen"
                            half
                        />
                    </div>
                    <div className="col-md-6">
                        <HomeCard
                            icon="fas fa-file"
                            title="Offerte aanvragen"
                            description="Hier kunt u eenvoudig een offerte aanvragen. Wij dan nemen zo spoedig mogelijk contact met u op."
                            link="/offerte/"
                            linkText="Offerte aanvragen"
                            half
                        />
                    </div>
                </div>

                {/*<HomeCard*/}
                {/*    icon="fas fa-money-bill-alt"*/}
                {/*    title="BTW terugvragen"*/}
                {/*    description="Kopers van zonnepanelen kunnen op eenvoudige wijze voortaan de BTW op de aanschaf en installatie van zonnepanelen terugkrijgen. Wij leveren deze service als optie op uw offerte."*/}
                {/*    link="/btwterugvraag/"*/}
                {/*/>*/}
                <HomeCard
                    icon="fas fa-money-bill-alt"
                    title="0% btw over zonnepanelen"
                    description="In 2023 hoeft over zonnepanelen voor woonhuizen geen btw betaalt te worden. Ontdek via onderstaande knop hoe dit precies zit."
                    link="/btw/"
                />
                <HomeCard
                    icon="fas fa-home"
                    title="Bekijk onze referenties"
                    description="Bekijk bestaande installaties door ons geïnstalleerd. Ook bij u in de buurt!"
                    link="https://referenties.smithsolar.nl"
                />
                <HomeCard
                    icon="fas fa-wrench"
                    title="Werken bij Smith!"
                    description="Wij zoeken collega's! Zou je bij ons willen werken? Bekijk dan onze openstaande vacatures!"
                    link="https://werkenbijsmith.nl"
                    linkText="Bekijk onze vacatures"
                />
                {/*<HomeCard*/}
                {/*    icon="fas fa-virus"*/}
                {/*    title="Corona-maatregelen"*/}
                {/*    description="Wij hanteren momenteel extra maatregelen vanwege het Coronavirus. Op deze pagina kunt u lezen wat deze zijn."*/}
                {/*    link="/corona/"*/}
                {/*    linkText="Corona-maatregelen"*/}
                {/*/>*/}
            </div>
        </React.Fragment>
    );
}

export default Home;
