import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Carousel
} from "react-bootstrap";

import solarConstructBackground from "./../../../img/producten/bevestigingsmateriaal-solarconstruct.jpg";
import flatFixFusionBackground from "./../../../img/producten/bevestigingsmateriaal-flatfixfusion.jpg";
import valkPitchedBackground from "./../../../img/producten/bevestigingsmateriaal-valkpitched.jpg";

import solarConstructLogo from "../../../img/brands/solarconstruct.svg";
import flatFixFusionLogo from "../../../img/brands/flatfixfusion.svg";
import vanDerValkSolarSystemsLogo from "../../../img/brands/vandervalksolarsystems.svg";

function ProductenBevestigingsmateriaalCarousel() {
    return (
        <Carousel className="carousel carousel-product slide" wrap>
            <Carousel.Item style={{ backgroundImage: "url('" + solarConstructBackground + "')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ solarConstructLogo } alt="SolarConstruct"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>Solar Construct</h3>
                            <p>Onze standaard keuze voor schuine daken</p>
                            <ul>
                                <li>Snel en eenvoudig gemonteerd voor een snellere montage.</li>
                                <li>Extra veilig gemonteerd voor een gegarandeerd stevige montage.</li>
                                <li><b>20 jaar garantie</b>.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item style={{ backgroundImage: "url('" + flatFixFusionBackground + "')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ flatFixFusionLogo } alt="FlatFix Fusion"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>FlatFix Fusion</h3>
                            <p>Onze standaard keuze voor platte daken</p>
                            <ul>
                                <li>Lichtgewicht montagesysteem.</li>
                                <li>Zeer snelle montage, tot 3 keer sneller dan andere systemen.</li>
                                <li>Geïntegreerd kabelmanagementsysteem.</li>
                                <li>Weinig ballast benodigd vanwege wind deflector panelen.</li>
                                <li><b>20 jaar garantie</b>.</li>
                            </ul>
                            <div className="btn-toolbar">
                                <div className="btn-group mr-2">
                                    <Link to="/producten/flat-fix-fusion/" className="btn btn-primary">Meer informatie</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item style={{ backgroundImage: "url('" + valkPitchedBackground + "')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ vanDerValkSolarSystemsLogo } alt="ValkPitched"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>ValkPitched</h3>
                            <p>Onze standaard keuze voor schuurdaken</p>
                            <ul>
                                <li>Logistiek voordeel door korte profiellengtes.</li>
                                <li>Indien gewenst ook lange lengtes verkrijgbaar.</li>
                                <li>Voorgeponste gaten in profielen voor snelle en eenvourdige montage.</li>
                                <li>Ook geschikt voor stalen golfplaatdaken.</li>
                                <li><b>10 jaar garantie</b>.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default ProductenBevestigingsmateriaalCarousel;
