import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../components/Helmet";
import JumbotronCallToAction from "../components/JumbotronCallToAction";
import CallToAction from "../components/CallToAction";
import ProductCard from "../components/ProductCard";
import MidCallToAction from "../components/MidCallToAction";

import solaredgeEvCharger from "../img/charger/solaredge-ev-charger.jpg";
import autelAcWallbox from "../img/charger/autel-ac-wallbox.jpg";

function Laadpalen() {
    return (
        <React.Fragment>
            <Helmet
                title="Laadpalen"
                description="Ontdek de voordelen van een eigen laadpaal thuis met Smith Solar B.V. Gemakkelijk, kostenefficiënt en duurzaam laden van uw elektrische auto. Vraag nu een offerte aan voor uw ideale laadoplossing en bereid u voor op de toekomst van elektrisch rijden. Verhoog de waarde van uw woning met onze slimme laadpalen."
            />

            {/*<LaadpaalSubNavbar/>*/}

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Laadpalen</h1>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container container-sm">

                <div className="article-open">
                    <p>
                        In het hedendaagse tijdperk van elektrisch rijden worden laadpalen thuis steeds meer een
                        standaard. Met de groeiende populariteit van elektrische auto's, is het hebben van een eigen
                        laadpaal niet alleen een gemak, maar ook een slimme investering. Smith Solar B.V. is uw expert
                        in laadoplossingen en staat klaar om u hierin te begeleiden!
                    </p>
                </div>

                <h2>
                    Laadpaal thuis voor uw elektrische auto
                </h2>
                <p>
                    Een laadpaal thuis betekent dat u altijd de zekerheid heeft van een volgeladen auto, klaar voor
                    gebruik. U hoeft zich geen zorgen te maken over het zoeken naar openbare laadstations, en u kunt
                    profiteren van lagere elektriciteitskosten, vooral als u zonnepanelen heeft.
                </p>

                <h2>
                    Hoe werkt een laadpaal thuis?
                </h2>
                <p>
                    Een laadpaal bij u thuis kan eenvoudig worden geïnstalleerd. Het is in wezen een station dat uw
                    elektrische auto van stroom voorziet. Deze laadpalen kunnen verschillende laadsnelheden hebben,
                    afhankelijk van het type dat u kiest. Ze worden direct aangesloten op uw elektrische installatie en
                    kunnen, indien gewenst, gekoppeld worden aan uw zonnepanelensysteem.
                </p>
                <p>
                    Het opladen van uw auto gaat eenvoudig en snel. U sluit uw auto aan met een laadkabel en de laadpaal
                    doet de rest. Veel laadpalen hebben slimme functies, zoals het instellen van laadtijden, het
                    bijhouden van verbruik, en zelfs integratie met uw thuisenergiesysteem voor maximale efficiëntie.
                </p>

            </div>

            <div className="container">
                <MidCallToAction/>
            </div>

            <div className="container container-sm">

                <h2>
                    Wat zijn de voordelen van een laadpaal thuis?
                </h2>
                <p>
                    De voordelen van een eigen laadpaal zijn aanzienlijk:
                </p>
                <ul>
                    <li className="mb-2">
                        <b>Gemak. </b>
                        U kunt uw auto opladen wanneer het u uitkomt, zonder afhankelijk te zijn van openbare laadstations.
                    </li>
                    <li className="mb-2">
                        <b>Kostenbesparing. </b>
                        Thuis laden is vaak goedkoper dan laden bij openbare stations, vooral als u gebruikmaakt van zelf opgewekte zonne-energie.
                    </li>
                    <li className="mb-2">
                        <b>Waardeverhoging. </b>
                        Net als bij zonnepanelen en thuisbatterijen, kan een laadpaal thuis de waarde van uw woning verhogen.
                    </li>
                    <li className="mb-2">
                        <b>Milieuvriendelijk. </b>
                        Als u de laadpaal combineert met zonne-energie, vermindert u uw CO2-voetafdruk aanzienlijk.
                    </li>
                    <li className="mb-2">
                        <b>Toekomstbestendig. </b>
                        Met de overgang naar elektrisch rijden bent u voorbereid op de toekomst.
                    </li>
                </ul>

                <h2>
                    Onze laadpalen
                </h2>
                <p>
                    Wij bieden verschillende laadpalen aan, geschikt voor diverse behoeften en budgetten. Hieronder
                    vindt u een overzicht van onze modellen.
                </p>

            </div>

            <div className="container">
                <ProductCard
                    title="SolarEdge EV Lader"
                    image={ solaredgeEvCharger }
                >
                    <ul>
                        <li>
                            Laadpaal voor 1 of 3 fase aansluiting, laden met 4,6 tot 22 kW vermogen
                        </li>
                        <li>
                            Beschikbaar met vaste of losse laadkabel
                        </li>
                        <li>
                            Integratie met uw SolarEdge omvormer, zodat u uw auto kunt opladen met uw eigen zonne-energie
                        </li>
                        <li>
                            Zonnepanelen, batterij en laadpaal allemaal in 1 app: mySolarEdge
                        </li>
                        <li>
                            Dynamic load balancing via de SolarEdge omvormer en Inline Energy Meter
                        </li>
                        <li>
                            Exporteer laaddata naar Excel voor doorberekening aan uw werkgever
                        </li>
                        <li>
                            Activeren met een RFID kaart of via de SolarEdge app
                        </li>
                    </ul>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>
                <ProductCard
                    title="Autel AC Wallbox"
                    image={ autelAcWallbox }
                >
                    <ul>
                        <li>
                            Laadpaal voor 1 fase (tot 7,4 kW) of 3 fase (tot 22 kW) aansluiting
                        </li>
                        <li>
                            Beschikbaar met vaste of losse laadkabel
                        </li>
                        <li>
                            Groot 5-inch LCD touchscreen om de laadpaal te bedienen en de status te bekijken
                        </li>
                        <li>
                            Dynamic load balancing via een meter in uw groepenkast
                        </li>
                        <li>
                            Activeren met een RFID kaart of via de Autel Charge app
                        </li>
                        <li>
                            Ook beschikbaar met 4G verbinding zodat uw laadpaal altijd online is
                        </li>
                    </ul>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>
            </div>

            <div className="container container-sm">


                <h2>
                    De toekomst van elektrisch rijden
                </h2>
                <p>
                    Met de voortdurende verbetering van elektrische auto's en de groei van duurzame energiebronnen,
                    wordt de rol van thuislaadstations steeds crucialer. De investering in een laadpaal thuis is een
                    stap naar een duurzamere en efficiëntere toekomst, zowel voor het milieu als voor uw portemonnee!
                </p>

            </div>

            <CallToAction/>

        </React.Fragment>
);
}

export default React.memo(Laadpalen);
