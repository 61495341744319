import React from "react";

import Helmet from "../../components/Helmet";

function OfferteBedankt() {
    return (
        <React.Fragment>
            <Helmet
                title="Bedankt"
                canonicalUrls={ [
                    "https://smithsolar.nl/offerte/bedankt/",
                    "https://smithsolar.nl/offerte/bedankt/zonnepanelen/",
                    "https://smithsolar.nl/offerte/bedankt/thuisbatterij/",
                    "https://smithsolar.nl/offerte/bedankt/zonnepanelen-thuisbatterij/",
                    "https://smithsolar.nl/offerte/bedankt/laadpaal/",
                ] }
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Offerte aanvragen</h1>
                </div>
            </div>
            <div className="container mb-5">
                <div className="text-center">
                    <h1>Bedankt voor uw offerte aanvraag!</h1>
                    <p>Wij gaan zo spoedig mogelijk contact met u opnemen.</p>
                </div>
                <div className="mt-4 text-center">
                    <p>
                        U zou alvast een kijkje kunnen nemen op onze referenties pagina naar bestaande installaties van
                        onze klanten.
                    </p>
                    <a href="https://referenties.smithsolar.nl" className="btn btn-primary">
                        Referenties bekijken
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(OfferteBedankt);
