import React from "react";
import Helmet from "../../components/Helmet";

import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

import schuindakBanner from "../../img/zonnepanelen/solar-zonnepanelen-schuindak-min.jpg";
import platdakBanner from "../../img/zonnepanelen/solar-zonnepanelen-platdak-min.jpg";
import schuurdakBanner from "../../img/zonnepanelen/solar-zonnepanelen-schuurdak-min.jpg";
import veldopstellingBanner from "../../img/zonnepanelen/solar-zonnepanelen-veldopstelling-min.jpg";

import bevestigingsMateriaalSchuin from "../../img/zonnepanelen/zonnepanelen-installeren.jpg";

import schuindakDatasheet from "../../files/datasheets/smithsolar-schuindak-datasheet.pdf";
import platdakDatasheet from "../../files/datasheets/smithsolar-platdak-datasheet.pdf";

function ZonnepanelenInstalleren() {
    return (
        <React.Fragment>
            <Helmet
                title="Installeren van zonnepanelen"
                description="Het installeren van zonnepanelen kunt u laten doen door de ervaren installateurs van Smith Solar BV of u kunt er voor kiezen om de zonnepanelen zelf te installeren, hiermee bespaart u op de installatiekosten. Wij proberen u op deze pagina stap voor stap een zo goed mogelijk beeld te verschaffen wat er allemaal komt kijken bij het installeren van zonnepanelen."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zonnepanelen</h1>
                    <p>Installeren van zonnepanelen</p>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container">

                <p>
                    Het installeren van zonnepanelen kunt u laten doen door de ervaren installateurs van Smith Solar BV
                    of u kunt er voor kiezen om de zonnepanelen zelf te installeren, hiermee bespaart u op de
                    installatiekosten. Wij proberen u op deze pagina stap voor stap een zo goed mogelijk beeld te
                    verschaffen wat er allemaal komt kijken bij het installeren van zonnepanelen. Onze complete
                    zonnepaneel pakketten bevat alle benodigde materialen om de zonnepanelen te monteren op het dak.
                </p>

            </div>

            <br/>

            <div style={{
                height: "400px",
                backgroundImage: "url('" + schuindakBanner + "')",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}/>

            <br/>

            <div className="container">

                <h2>Schuin dak</h2>

                <p>
                    Voor schuine daken leveren wij bij onze pakketten het montagesysteem van het Nederlands bedrijf<b>Solar
                    Construct</b>. Het Solar Construct montagesysteem is eenvoudig te installeren op het dak, zo is er
                    geen gereedschap of montagemateriaal nodig voor het bevestigingen van de dakhaak en de montagarail.
                    Voor het vastzetten van de zonnepanelen heeft u alleen een ratel met dop 10 en een accuboor nodig op
                    de schroeven vast te zetten.
                </p>
                <p>
                    Op onderstaande foto ziet u de producten die u nodig heeft voor het installeren van zonnepanelen op
                    een schuin dak.
                </p>

                <ul>
                    <li>
                        <b>Dakhaak:</b> Deze dakhaak bevestigt u achter de panlat en de dakpan. De dakhaak klemt zicht
                        vast achter de panlat en dakpan.
                    </li>
                    <li>
                        <b>Rails:</b> De rails kunt u eenvoudig vast klikken op de dakhaak.
                    </li>
                    <li>
                        <b>Koppelset:</b> Indien u gebruik maakt van meedere railsen kunt u deze railsen eenvoudig aan
                        elkaar vast maken met behulp van de koppelset.
                    </li>
                    <li>
                        <b>Tussenklemmen:</b> Met deze klemmen worden de zonnepanelen vastgeschroefd aan de rails.
                    </li>
                    <li>
                        <b>Eindklemmen:</b> De eindklemmen komen aan het eind van een rij om de zonnepanelen vast te
                        zetten. Deze klem schuift u om de rails heen.
                    </li>
                    <li>
                        <b>Montagehulpset:</b> Met deze hulpset kunt u de zonnepanelen eerst aan de rail hangen voordat
                        u ze definitief vast zet.
                    </li>
                </ul>

                <div className="text-center">
                    <img src={ bevestigingsMateriaalSchuin } style={{ width: "100%", maxWidth: "587px" }} alt="Bevestigingsmateriaal"/>
                </div>

                <br/>

                <div className="text-center">
                    <p>Klik op de knop hieronder om onze eigen datasheet voor dit montage materiaal te lezen.</p>
                    <a
                        href={ schuindakDatasheet }
                        className="btn btn-primary"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Schuin dak datasheet
                    </a>
                </div>

            </div>

            <br/>

            <div style={{
                height: "400px",
                backgroundImage: "url('" + platdakBanner + "')",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}/>

            <br/>

            <div className="container">

                <h2>Plat dak</h2>

                <p>Voor het plaatsen van zonnepanelen op platte daken gebruiken wij een ander montage
                    systeem. Dit systeem is
                    het <b>FlatFix Fusion</b> montage systeem ontwikkeld door <b>Esdec</b>. Met dit
                    montage systeem is niet
                    alleen het meestvoorkomende Zuid opstelling mogelijk, maar ook een Oost-West
                    opstelling. Beide opstellingen
                    leveren wij voor zonnepanelen op uw platdak woning, schuur of bedrijfspand.</p>

                <p>Door te kiezen voor een platdak opstelling geplaatst door ons, zal u alle voordelen
                    van dit montage ontvangen:</p>
                <ul>
                    <li><b>Lichtgewicht montagesysteem</b>, zodat minder stres op het dak geplaatst
                        wordt.
                    </li>
                    <li><b>Zeer snelle montage</b>, tot 3 keer sneller dan andere systemen voor een
                        snelle installatie, meestal binnen 1 dag.
                    </li>
                    <li><b>Geïntegreerd kabelmanagementsysteem</b>, om uw kabels uit het water te houden
                        dan op het dak kan blijven staan.
                    </li>
                    <li><b>Minder balast benodigd</b>, met dank aan de wind deflectoren aan de zijkant
                        en achterkant van de opstelling.
                    </li>
                    <li><b>20 jaar garantie</b> op het volledige montage systeem.</li>
                </ul>

                <br/>

                <div className="text-center">
                    <p>Klik op de knop hieronder om onze eigen datasheet voor dit montage materiaal te lezen.</p>
                    <a
                        href={ platdakDatasheet }
                        className="btn btn-primary"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Plat dak datasheet
                    </a>
                </div>

            </div>

            <br/>

            <div style={{
                height: "400px",
                backgroundImage: "url('" + schuurdakBanner + "')",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}/>

            <br/>

            <div className="container">

                <h2>Schuur dak</h2>

            </div>

            <br/>

            <div style={{
                height: "400px",
                backgroundImage: "url('" + veldopstellingBanner + "')",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}/>

            <br/>

            <div className="container">

                <h2>Veld opstelling</h2>

            </div>

            <CallToAction/>
        </React.Fragment>
    )
}

export default ZonnepanelenInstalleren;
