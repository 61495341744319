import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";

import useScrollPosition from "../../hooks/ScrollHook";
import useWindowSize from "../../hooks/WindowSize";
import ThemeContext from "../../context/ThemeContext";
import NavbarLink from "./NavbarLink";
import clamp from "../../utils/clamp";
import NavbarCompanyLinks from "./NavbarCompanyLinks";
import ZonnepanelenSubNavbar from "./specific/ZonnepanelenSubNavbar";
import ThuisbatterijSubNavbar from "./specific/ThuisbatterijSubNavbar";

import solarLogo from "../../img/solar-logo.svg";
import solarWhiteLogo from "../../img/solar-logo-white.svg";
import solarChristmasLogo from "../../img/solar-logo-christmas.svg";
import solarWhiteChristmasLogo from "../../img/solar-logo-white-christmas.svg";

function Navbar() {
    const theme = useContext(ThemeContext);
    const navbarRef = useRef();
    const secondaryRef = useRef();

    const scrollPosition = useScrollPosition();
    const windowSize = useWindowSize();

    useEffect(() => {
        let height = 0;
        if(secondaryRef.current) {
            height = secondaryRef.current.offsetHeight;
        }
        if(navbarRef.current) {
            height += navbarRef.current.offsetHeight;
        }
        theme.setCurrentNavbarHeight(height);
    }, [theme, scrollPosition, windowSize, navbarRef, secondaryRef]);

    const [showCollapsed, setShowCollapsed] = useState(false);
    const collapse = useCallback(() => {
        setShowCollapsed(false);
    }, []);

    const matchTransparentNavbar = useRouteMatch({
        path: [
            "/",
            "/campagne/boelhuis",
            "/campagne/gazetta",
            "/campagne/helmheus",
            "/campagne/mobile",
            "/campagne/mobile-gasprijzen",
            "/campagne/mobile-actie"
        ],
        exact: true
    });
    const zonnepanelenSubNavbarMatch = useRouteMatch({
        path: [
            "/zonnepanelen",
            "/producten",
            "/zonnepanelen/voordelen",
            "/zonnepanelen/installeren",
            "/zonnepanelen/btw",
            "/zonnepanelen/subsidie",
        ],
        exact: true
    });
    const thuisbatterijSubNavbarMatch = useRouteMatch({
        path: [
            "/thuisbatterij",
            "/thuisbatterij/installatie",
            "/thuisbatterij/hoe-werkt-het",
            "/thuisbatterij/btwterugvraag",
        ],
        exact: true
    });
    const subNavbar = useMemo(() => {
        if(zonnepanelenSubNavbarMatch) {
            return <ZonnepanelenSubNavbar/>;
        }
        if(thuisbatterijSubNavbarMatch) {
            return <ThuisbatterijSubNavbar/>;
        }
    },[zonnepanelenSubNavbarMatch, thuisbatterijSubNavbarMatch]);

    let hideLinks = theme.hideLinks;
    const mobile = windowSize.width < 992;

    let secondaryNavBarClassNames = ["navbar", "navbar-expand-lg", "navbar-expand", "fixed-top", "navbar-second"];
    let mainNavBarClassNames = ["fixed-top", "navbar-main"];
    let spacerClassNames = [];
    if(!matchTransparentNavbar) {
        if(mobile) {
            spacerClassNames.push("no-small-navbar");
        }
        spacerClassNames.push("navbar-page-spacer");
    } else if(!mobile) {
        mainNavBarClassNames.push("navbar-transparent")
        hideLinks = true;
    }
    if(theme.darkNavbar) {
        mainNavBarClassNames.push("navbar-dark");
        secondaryNavBarClassNames.push("navbar-dark bg-dark");
    } else {
        secondaryNavBarClassNames.push("navbar-light bg-light");
    }
    if(scrollPosition.y > 0) {
        mainNavBarClassNames.push("navbar-scrolled");
    }
    if(hideLinks) {
        mainNavBarClassNames.push("mt-0");
        spacerClassNames.push("no-small-navbar");
    }
    if(subNavbar) {
        mainNavBarClassNames.push("no-dropshadow");
    }

    const logo = useMemo(() => {
        if(theme.darkNavbar) {
            return theme.christmas ? solarWhiteChristmasLogo : solarWhiteLogo;
        }
        if(matchTransparentNavbar && scrollPosition.y <= 0 && !mobile) {
            return theme.christmas ? solarWhiteChristmasLogo : solarWhiteLogo;
        }
        return theme.christmas ? solarChristmasLogo : solarLogo;
    }, [theme, matchTransparentNavbar, scrollPosition, mobile]);

    const mainInitialHeight = mobile ? 0 : 26;

    return (
        <React.Fragment>
            { !hideLinks && !mobile && (
                <nav
                    className={ secondaryNavBarClassNames.join(" ") }
                    ref={ secondaryRef }
                    style={{
                        position: scrollPosition.y > 0 ? "absolute" : "fixed"
                    }}
                >
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav mr-auto">
                                { theme.blackFriday && (
                                    <li className="nav-item d-none d-lg-block">
                                        <Link to="/offerte/" className="nav-link black-friday">
                                            Full Black Friday - 5% korting op alle installaties
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            <ul className="navbar-nav">
                                <NavbarCompanyLinks/>
                            </ul>
                        </div>
                    </div>
                </nav>
            )}

            <RBNavbar
                variant={ theme.darkNavbar ? "dark" : "light" }
                expand="lg"
                className={ mainNavBarClassNames.join(" ") }
                expanded={ showCollapsed }
                ref={ navbarRef }
                style={{
                    marginTop: clamp(mainInitialHeight - scrollPosition.y, 0, mainInitialHeight) + "px",
                }}
            >
                <Container fluid>
                    <a className="navbar-brand navbar-brand-desktop" href="/">
                        <img
                            src={ logo }
                            alt="Smith Solar B.V."
                            height={ clamp(100 - Math.max(scrollPosition.y - mainInitialHeight, 0), 60, 100) + "px" }
                        />
                    </a>
                    <a className="navbar-brand navbar-brand-small" href="/">
                        <img
                            src={ logo }
                            alt="Smith Solar B.V."
                            height="60px"
                        />
                    </a>

                    { !theme.hideLinks && (
                        <React.Fragment>
                            <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                            <RBNavbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto"/>
                                <Nav>
                                    {/*<NavbarLink*/}
                                    {/*    to="/"*/}
                                    {/*    title="Home"*/}
                                    {/*    exact*/}
                                    {/*    onClick={ collapse }*/}
                                    {/*    hidden={ windowSize.width >= 992 && windowSize.width < 1230 }*/}
                                    {/*/>*/}
                                    <NavbarLink to="/zonnepanelen/" title="Zonnepanelen" onClick={ collapse } active={ zonnepanelenSubNavbarMatch }/>
                                    <NavbarLink to="/thuisbatterij/" title="Thuisbatterij" onClick={ collapse }/>
                                    <NavbarLink to="/laadpalen/" title="Laadpalen" onClick={ collapse }/>
                                    <NavbarLink to="/ems/" title="EMS" onClick={ collapse }/>
                                    {/*<li className="nav-item"><a className="nav-link" href="https://referenties.smithsolar.nl" onClick={ collapse }>Referenties</a></li>*/}
                                    <NavbarLink to="/support/" title="Support" onClick={ collapse }/>
                                    {/*<NavbarLink to="/contact/" title="Contact" exact onClick={ collapse }/>*/}
                                    <div className="d-flex flex-row align-items-center ml-2">
                                        <Link
                                            to="/offerte/"
                                            className="btn btn-primary"
                                            onClick={ collapse }
                                        >
                                            Offerte aanvragen
                                        </Link>
                                    </div>
                                    <li className="nav-item nav-item-hidden-desktop-w"><hr/></li>
                                    <NavbarCompanyLinks mobileOnly={ true } onClick={ collapse }/>
                                </Nav>
                            </RBNavbar.Collapse>
                        </React.Fragment>
                    )}
                </Container>
            </RBNavbar>

            <div className={ spacerClassNames.join(" ") }/>

            { subNavbar }

        </React.Fragment>
    );
}

export default Navbar;
