import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import ProductCard from "../../components/ProductCard";
import MidCallToAction from "../../components/MidCallToAction";

import se48v from "../../img/battery/solaredge-home-battery-48V.jpg";
import se400v from "../../img/battery/solaredge-home-battery-400V.png";
import bydlvs from "../../img/battery/byd-lvs.png";
import vvvCard from "../../img/vvv-card.jpg";

function Thuisbatterij() {
    return (
        <React.Fragment>
            <Helmet
                title="Thuisbatterij"
                description="Met een thuisbatterij bent u minder afhankelijk van het energienet en kunt u eigen groene stroom op een later moment gebruiken."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Thuisbatterij</h1>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container container-sm">

                <div className="article-open">
                    <p>
                        Tegenwoordig begint een thuisbatterij steeds aantrekkelijker te worden. Hoge energieprijzen,
                        onzekerheden rondom salderen in de toekomst, etc. Smith Solar B.V. is expert in thuisbatterijen en
                        helpt u hier graag mee!
                    </p>
                </div>

                <h2>
                    Thuisbatterij voor zonnepanelen
                </h2>
                <p>
                    Een thuisbatterij wordt gebruikt om de energie die wordt opgewekt door zonnepanelen op te
                    slaan, zodat deze later kan worden gebruikt wanneer de zon niet schijnt. Het opslaan van de
                    energie die overdag wordt opgewekt, zorgt ervoor dat de opgewekte energie niet verloren
                    gaat en kan worden gebruikt wanneer het nodig is.
                </p>

                <h2>
                    Hoe werkt een thuisbatterij?
                </h2>
                <p>
                    Een thuisbatterij komt in alle maten en vormen voor. Over het algemeen is een thuisbatterij is een grote
                    kast gevuld met batterijen die bij u thuis geplaatst wordt. Deze wordt vervolgens met uw omvormer
                    gekoppeld om automatisch overgebleven opgewekte zonnestroom op te slaan in de accu.
                </p>
                <p>
                    Opgewekte zonnestroom gaat als eerste naar de apparaten in huis die op dit moment stroom verbruiken.
                    Extra opgewekte zonnestroom die voorheen teruggeleverd werd, wordt nu automatisch gebruikt om de
                    thuisbatterij op te laden. Dit gebeurt met de hulp van een energiemeter in uw meterkast die
                    gekoppeld staat met uw omvormer. Zodra de omvormer ziet dat er geen of te weinig zonnestroom wordt
                    opgewekt om uw eigen apparaten te voeden, zal deze de batterij aansturen om te ontladen. Zo kunt u
                    eerder opgewekte stroom dus 's avonds gebruiken.
                </p>

            </div>

            <div className="container">
                <MidCallToAction/>
            </div>

            <div className="container container-sm">

                <h2>
                    Wat zijn de voordelen van een thuisbatterij?
                </h2>
                <p>
                    Er zijn een aantal voordelen om nu al een thuisbatterij te halen:
                </p>
                <ul>
                    <li className="mb-2">
                        <b>Duurzaamheid. </b>
                        Door een thuisbatterij bij u thuis te plaatsen wordt uw energieverbruik een stuk duurzamer.
                        Waar u met alleen zonnepanelen 's avonds en 's nachts grijze energie van vervuilende
                        centrales gebruikt, zullen deze met een thuisbatterij worden gevoed met uw eigen groene zonnestroom!
                        Daarnaast helpt u mee het energienet te balanceren wat toedraagt aan de energietransitie voor een schoner klimaat.
                    </li>
                    <li className="mb-2">
                        <b>Onafhankelijkheid. </b>
                        Met een thuisbatterij wordt u nog minder afhankelijk van het energienet dat met alleen
                        zonnepanelen. U bent ongeveer 30% onafhankelijk met alleen zonnepanelen. Dit stijgt naar ongeveer
                        70% als u daar een thuisbatterij aan toevoegt! U heeft dan nauwelijks meer last van stijgende
                        energieprijzen.
                    </li>
                    <li className="mb-2">
                        <b>Voorbereid. </b>
                        In de komende jaren zal de salderingsregeling afgeschaft worden. Dit heeft tot gevolg dat u extra
                        opgewekte stroom dat wordt teruggeleverd niet meer vergoed zal krijgen. Door een thuisbatterij
                        aan uw installatie toe te voegen zal u deze extra opgewekte stroom alsnog zelf kunnen gebruiken.
                    </li>
                    <li className="mb-2">
                        <b>Noodgevallen. </b>
                        Er zijn ook mogelijkheden om de thuisbatterij als backupstroom te gebruiken. Als er een stroomstoring
                        plaatsvindt in het energienet kunt u uw apparaten verder voeden op de opgeslagen energie uit uw
                        thuisbatterij. Nou is het energienet in Nederland erg stabiel, maar je bent blij met de investering
                        als het wel een keer gebeurt. Vraag ons naar de mogelijkheden!
                    </li>
                    <li className="mb-2">
                        <b>Waardestijging. </b>
                        Een woning met zonnepanelen en een thuisbatterij wordt gezien als een waardevolle
                        investering en kan de waarde van de woning verhogen. Dit komt doordat potentiële kopers
                        geïnteresseerd zijn in duurzame en energiezuinige woningen. Door te investeren in een
                        thuisbatterij, investeer je dus niet alleen in je eigen huis, maar ook in de waarde ervan voor
                        de toekomst.
                    </li>
                </ul>

                <h2>
                    Oké, maar hoe ziet zo'n thuisbatterij er dan uit?
                </h2>
                <p>
                    Thuisbatterijen zijn er in verschillende soorten en maten. Hieronder een aantal van onze opties
                    beschreven. Vraag naar de overige opties.
                </p>

            </div>
            <div className="container">

                <ProductCard
                    title="BYD Battery-Box Premium LVS"
                    image={ bydlvs }
                >
                    <ul>
                        <li>
                            Ondersteuning 3-fase SolarEdge omvormers
                        </li>
                        <li>
                            Uitbreiden van 4 kWh tot 24 kWh per omvormer
                        </li>
                        <li>
                            Modulair uitgevoerd, dus kan op zolder geïnstalleerd worden
                        </li>
                        <li>
                            Efficiëntie van >95%
                        </li>
                        <li>
                            Voldoet aan de strengste veiligheidseisen
                        </li>
                        <li>
                            10 jaar garantie
                        </li>
                    </ul>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>

                <ProductCard
                    title="SolarEdge Home Battery 400V"
                    image={ se400v }
                >
                    <ul>
                        <li>
                            Ondersteuning 1-fase SolarEdge omvormers
                        </li>
                        <li>
                            Uitbreiden van 10 kWh tot 30 kWh per omvormer
                        </li>
                        <li>
                            Kan tot 50 meter van de omvormer vandaan geplaatst worden
                        </li>
                        <li>
                            Kan ook buiten geplaatst worden (schaduw, onder afdakje)
                        </li>
                        <li>
                            Kan op reguliere SolarEdge HD-Wave omvormers aangesloten worden
                        </li>
                        <li>
                            Efficiëntie van 94,5%
                        </li>
                        <li>
                            Voldoet aan de strengste veiligheidseisen
                        </li>
                        <li>
                            10 jaar garantie
                        </li>
                    </ul>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>

                <ProductCard
                    title="SolarEdge Home Battery 48V"
                    image={ se48v }
                >
                    <ul>
                        <li>
                            Ondersteuning 3-fase SolarEdge omvormers
                        </li>
                        <li>
                            Uitbreiden van 4,6 kWh tot 23 kWh per omvormer
                        </li>
                        <li>
                            Modulair uitgevoerd, dus kan op zolder geïnstalleerd worden
                        </li>
                        <li>
                            Efficientie van 95,4%
                        </li>
                        <li>
                            Voldoet aan de strengste veiligheidseisen
                        </li>
                        <li>
                            10 jaar garantie
                        </li>
                    </ul>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>

                <div className="card-rounded mb-3">
                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="actie-header">
                                € 600,- tegoedbon!
                            </h3>
                            <p className="card-text">
                                Na installatie van een SolarEdge batterij krijgt u gratis een € 600,- VVV tegoedbon van
                                SolarEdge. Deze actie wordt door ons aangeboden in samenwerking met SolarEdge en is geldig
                                tot 30 juni 2024. Deze actie is van toepassing op de 48V en 400V SolarEdge batterijen.
                                Neem contact op voor de actievoorwaarden.
                            </p>
                            <Link
                                to="/offerte/"
                                className="btn btn-primary"
                            >
                                Offerte aanvragen
                            </Link>
                        </div>
                        <div className="col d-flex justify-content-center">
                            <img
                                src={ vvvCard }
                                alt="VVV Cadeaubon"
                                className="img-fluid mt-3 mt-md-0"
                                style={{ maxHeight: "150px" }}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <div className="container" style={{ maxWidth: "800px" }}>

                <h2>
                    De toekomst
                </h2>
                <p>
                    Thuisbatterijen voor zonnepanelen zijn een belangrijk onderdeel van een duurzaam
                    energiesysteem en bieden tal van voordelen, waaronder het verminderen van de
                    afhankelijkheid van het elektriciteitsnet en het verlagen van de energiekosten. Met de
                    ontwikkeling van nieuwe technologieën en slimme netwerken ziet de toekomst er
                    veelbelovend uit voor zonne-energieopslag.
                </p>

            </div>

            <CallToAction/>

        </React.Fragment>
    )
}

export default React.memo(Thuisbatterij);
