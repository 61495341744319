import React, {
    createRef
} from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import Spinner from "react-spinner-material";
import ReCAPTCHA from "react-google-recaptcha";

import Helmet from "../../components/Helmet";
import FormSelectionCard from "./components/FormSelectionCard";
import {
    withThemeContext
} from "../../context/ThemeContext";

const quoteType = [
    {
        name: "Zonnepanelen",
        value: "zonnepanelen",
        showPowerUsage: true,
        icons: [
            "fas fa-solar-panel"
        ]
    },
    {
        name: "Thuisbatterij",
        value: "thuisbatterij",
        showPowerUsage: true,
        icons: [
            "fas fa-battery-full"
        ]
    },
    {
        name: "Zonnepanelen met thuisbatterij",
        value: "zonnepanelen-thuisbatterij",
        showPowerUsage: true,
        icons: [
            "fas fa-solar-panel",
            "fas fa-battery-full"
        ]
    },
    {
        name: "Laadpaal",
        value: "laadpaal",
        icons: [
            "fas fa-charging-station"
        ]
    },
    {
        name: "Slimme sturing (EMS)",
        value: "ems",
        icons: [
            "fa-solid fa-meter-bolt"
        ]
    },
];

const customerType = [
    {
        name: "Woonhuis",
        value: "particulier",
        icons: [
            "fas fa-house"
        ]
    },
    {
        name: "Bedrijfspand",
        value: "zakelijk",
        icons: [
            "fas fa-industry"
        ]
    },
];

const emsExistingSystem = [
    {
        name: "Ik heb nog geen systeem",
        value: "Geen",
        icons: [
            "fas fa-stars"
        ]
    },
    {
        name: "SolarEdge",
        value: "SolarEdge",
        icons: [
            "fas fa-solar-panel"
        ]
    },
    {
        name: "Afore",
        value: "Afore",
        icons: [
            "fas fa-solar-panel"
        ]
    },
    {
        name: "GoodWe",
        value: "GoodWe",
        icons: [
            "fas fa-solar-panel"
        ]
    },
    {
        name: "FoxESS",
        value: "FoxESS",
        icons: [
            "fas fa-solar-panel"
        ]
    },
    {
        name: "Anders (vermeld in opmerkingen)",
        value: "Anders",
        icons: [
            "fa-solid fa-circle-question"
        ]
    },
];

class Offerte extends React.PureComponent {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
        this.state = {
            loading: false,
            error: null,

            quoteType: null,
            customerType: null,
            emsExistingSystem: null,

            name: "",
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",
            phoneNumber: "",
            email: "",

            powerUsage: "",
            message: "",

            acceptPrivacyPolicy: false
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.quoteTypeSelectionRef = createRef();
        this.customerTypeSelectionRef = createRef();
        this.emsExistingSystemSelectionRef = createRef();
        this.formCardRef = createRef();
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.setState({ error: null, loading: true });
        this.recaptchaRef.current.reset();
        this.recaptchaRef.current.execute();
    }

    onRecaptchaVerify(recaptchaData) {
        console.log(recaptchaData);
        if(recaptchaData !== null) {
            this.submitFormData(recaptchaData);
        } else {
            this.setState({ loading: false });
        }
    }

    submitFormData(recaptchaData) {
        if(!this.state.acceptPrivacyPolicy) {
            this.setState({ error: "Het is vereist om akkoord te gaan met de privacyverklaring.", loading: false });
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        const quoteType = this.getSelectedQuoteType();
        if(quoteType.showPowerUsage && this.state.powerUsage === "") {
            this.setState({ error: "Niet alle velden zijn ingevuld.", loading: false });
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        const customerType = this.getSelectedCustomerType();
        const emsExistingSystem = this.getSelectedEMSExistingSystem();

        let message = "";
        if(quoteType.showPowerUsage) {
            message += "**Jaarverbruik**: " + this.state.powerUsage + "\n";
        }
        message += "**Type aanvraag**: " + quoteType.name + "\n";
        message += "**Type klant**: " + customerType.name + "\n";
        if(quoteType.value === "ems") {
            message += "**Bestaande systeem**: " + emsExistingSystem.name + "\n";
        }
        message += "\n";
        message += this.state.message;

        const type = quoteType.value + "-" + customerType.value;

        const data = {
            recaptchaData: recaptchaData,
            type,
            name: this.state.name,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            message
        }
        axios.post("/submitOfferteAanvraag", data)
            .then((response) => {
                if(response.data.valid) {
                    this.props.history.push("/offerte/bedankt/" + quoteType.value + "/");
                } else {
                    this.setState({ error: response.data.error });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                window.scrollTo({ top: 0, behavior: "smooth" });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getSelectedQuoteType() {
        const type = this.state.quoteType;
        return quoteType.find((findType) => findType.name === type);
    }

    didSelectQuoteType(quoteType) {
        this.setState({ quoteType: quoteType.name }, () => {
            this.handleAutoScroll();
        });
    }

    getSelectedCustomerType() {
        const type = this.state.customerType;
        return customerType.find((findType) => findType.name === type);
    }

    didSelectCustomerType(customerType) {
        this.setState({ customerType: customerType.name }, () => {
            this.handleAutoScroll();
        });
    }

    getSelectedEMSExistingSystem() {
        const type = this.state.emsExistingSystem;
        return emsExistingSystem.find((findType) => findType.name === type);
    }

    didSelectEMSExistingSystem(emsExistingSystem) {
        this.setState({ emsExistingSystem: emsExistingSystem.name }, () => {
            this.handleAutoScroll();
        });
    }

    handleAutoScroll() {
        const selectedQuoteType = this.getSelectedQuoteType();
        const selectedCustomerType = this.getSelectedCustomerType();
        const selectedEMSExistingSystem = this.getSelectedEMSExistingSystem();

        if(!selectedQuoteType) {
            this.scrollToElement(this.quoteTypeSelectionRef.current);
        } else if(!selectedCustomerType) {
            this.scrollToElement(this.customerTypeSelectionRef.current);
        } else if(selectedQuoteType?.value === "ems" && !selectedEMSExistingSystem) {
            this.scrollToElement(this.emsExistingSystemSelectionRef.current);
        } else {
            this.scrollToElement(this.formCardRef.current);
        }
    }

    scrollToElement(element) {
        if(!element) {
            return;
        }
        let offset = 100;
        if(this.props.themeContext && this.props.themeContext.currentNavbarHeight !== null) {
            offset = this.props.themeContext.currentNavbarHeight + 14;
        }
        setTimeout(() => {
            window.scrollTo({
                top: element.offsetTop - offset,
                behavior: "smooth"
            });
        }, 100);
    }

    isValidTypes() {
        const selectedQuoteType = this.getSelectedQuoteType();
        const selectedCustomerType = this.getSelectedCustomerType();
        const selectedEMSExistingSystem = this.getSelectedEMSExistingSystem();

        if(!selectedQuoteType || !selectedCustomerType) {
            return false;
        }
        if(selectedQuoteType?.value === "ems" && !selectedEMSExistingSystem) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Offerte aanvragen"/>

                <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                    <div className="container">
                        <h1>Offerte aanvragen</h1>
                    </div>
                </div>
                <div className="container mb-5">
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    <form onSubmit={ this.onFormSubmit }>
                        <div className="card mb-3" ref={ this.quoteTypeSelectionRef }>
                            <div className="card-body pb-3">
                                <h3>Type aanvraag</h3>
                                <p>Waarvoor wilt u een offerte ontvangen?</p>
                                <div className="row">
                                    { quoteType.map((value, index) => (
                                        <FormSelectionCard
                                            key={ index }
                                            option={ value }
                                            selected={ this.state.quoteType === value.name }
                                            onClick={ () => this.didSelectQuoteType(value) }
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3" ref={ this.customerTypeSelectionRef }>
                            <div className="card-body pb-3">
                                <h3>Type klant</h3>
                                <p>Waarvoor is uw aanvraag?</p>
                                <div className="row">
                                    { customerType.map((value, index) => (
                                        <FormSelectionCard
                                            key={ index }
                                            option={ value }
                                            selected={ this.state.customerType === value.name }
                                            onClick={ () => this.didSelectCustomerType(value) }
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        { this.getSelectedQuoteType()?.value === "ems" && (
                            <div className="card mb-3" ref={ this.emsExistingSystemSelectionRef }>
                                <div className="card-body pb-3">
                                    <h3>Bestaande systeem</h3>
                                    <p>Welk merk systeem is al aanwezig?</p>
                                    <div className="row">
                                        { emsExistingSystem.map((value, index) => (
                                            <FormSelectionCard
                                                key={ index }
                                                option={ value }
                                                selected={ this.state.emsExistingSystem === value.name }
                                                onClick={ () => this.didSelectEMSExistingSystem(value) }
                                            />
                                        )) }
                                    </div>
                                </div>
                            </div>
                        )}

                        { this.isValidTypes() && (
                            <React.Fragment>
                                <div className="card mb-3" ref={ this.formCardRef }>
                                    <div className="card-body">
                                        <h3>Uw informatie</h3>
                                        <div className="form-group">
                                            <label htmlFor="name">Naam</label>
                                            <input
                                                type="text" className="form-control" id="name" name="name" placeholder="Naam" required
                                                value={ this.state.name }
                                                onChange={ (event) => this.setState({ name: event.target.value }) }
                                                disabled={ this.state.loading }
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-8">
                                                <div className="form-group">
                                                    <label htmlFor="street">Straat</label>
                                                    <input
                                                        type="text" className="form-control" id="street" name="street" placeholder="Straat" required
                                                        value={ this.state.street }
                                                        onChange={ (event) => this.setState({ street: event.target.value }) }
                                                        disabled={ this.state.loading }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="housenumber">Huisnummer + Toevoeging</label>
                                                    <input
                                                        type="text" className="form-control" id="housenumber" name="housenumber" placeholder="Huisnummer + Toevoeging" required
                                                        value={ this.state.houseNumber }
                                                        onChange={ (event) => this.setState({ houseNumber: event.target.value }) }
                                                        disabled={ this.state.loading }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="postalcode">Postcode</label>
                                            <input
                                                type="text" className="form-control" id="postalcode" name="postalcode" placeholder="Postcode" required
                                                value={ this.state.postalCode }
                                                onChange={ (event) => this.setState({ postalCode: event.target.value }) }
                                                disabled={ this.state.loading }
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="city">Plaats</label>
                                            <input
                                                type="text" className="form-control" id="city" name="city" placeholder="Plaats" required
                                                value={ this.state.city }
                                                onChange={ (event) => this.setState({ city: event.target.value }) }
                                                disabled={ this.state.loading }
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="phonenumber">Telefoonnummer</label>
                                            <input
                                                type="text" className="form-control" id="phonenumber" name="phonenumber" placeholder="Telefoonnummer" required
                                                value={ this.state.phoneNumber }
                                                onChange={ (event) => this.setState({ phoneNumber: event.target.value }) }
                                                disabled={ this.state.loading }
                                            />
                                            <small className="form-text text-muted">
                                                Wij raden aan uw 06-nummer in te vullen. Dan kunnen wij u beter bereiken.
                                            </small>
                                        </div>

                                        <div className="form-group mb-0">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email" className="form-control" id="email" name="email" placeholder="Email" required
                                                value={ this.state.email }
                                                onChange={ (event) => this.setState({ email: event.target.value }) }
                                                disabled={ this.state.loading }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        { this.getSelectedQuoteType().showPowerUsage && (
                                            <div className="form-group">
                                                <label htmlFor="powerUsage">Jaarverbruik</label>
                                                <select
                                                    className="form-control"
                                                    id="powerUsage"
                                                    value={ this.state.powerUsage }
                                                    onChange={ (event) => this.setState({ powerUsage: event.target.value }) }
                                                >
                                                    <option value=""/>
                                                    { [
                                                        "1800 kWh - Eenpersoonshuishouden",
                                                        "2700 kWh - Tweepersoonshuishouden",
                                                        "3500 kWh - Klein gezin",
                                                        "4500 kWh - Gemiddeld gezin",
                                                        "5000 kWh - Groot gezin",
                                                        "Meer dan 5000 kWh - Vermelden in opmerkingen"
                                                    ].map((value, index) => (
                                                        <option value={ value } key={ index }>{ value }</option>
                                                    )) }
                                                </select>
                                            </div>
                                        )}

                                        <div className="form-group mb-0">
                                            <label htmlFor="message">Uw opmerking/wensen <small
                                                className="text-muted">(optioneel)</small></label>
                                            <textarea
                                                className="form-control" id="message" name="message" style={{height: "100px"}}
                                                value={this.state.message}
                                                onChange={(event) => this.setState({message: event.target.value})}
                                                disabled={ this.state.loading }
                                            />
                                            <small className="form-text text-muted">
                                                Meld hier optioneel uw dak situatie en/of stroomverbruik. Daarmee kunnen we u sneller van dienst zijn.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox" className="custom-control-input" name="iaccept" id="iaccept"
                                                checked={ this.state.acceptPrivacyPolicy }
                                                onChange={ (event) => this.setState({ acceptPrivacyPolicy: event.target.checked }) }
                                                disabled={ this.state.loading }
                                            />
                                            <label className="custom-control-label" htmlFor="iaccept">
                                                Ik ga akkoord met de <a href="/privacyverklaring/" target="_blank">privacyverklaring <small><i className="fas fa-external-link"/></small></a>.
                                            </label>
                                        </div>
                                        <small className="text-muted">
                                            This site is protected by reCAPTCHA and the Google{" "}
                                            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and{" "}
                                            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener">Terms of Service</a> apply.
                                        </small>
                                    </div>
                                </div>

                                { this.state.loading ? (
                                    <div className="float-right">
                                        <Spinner radius={40} color="#414C36" stroke={5} visible={true} />
                                    </div>
                                ) : (
                                    <input type="submit" className="btn btn-primary float-right" value="Versturen"/>
                                )}
                            </React.Fragment>
                        )}

                        <ReCAPTCHA
                            ref={ this.recaptchaRef }
                            size="invisible"
                            sitekey="6LcavTIUAAAAADLEdpsrKb9D0O5oKww7p1ZcFKEg"
                            onChange={ this.onRecaptchaVerify.bind(this) }
                        />
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withThemeContext(Offerte));
